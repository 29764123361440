import React from 'react'
import { graphql } from 'gatsby'
//import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Behandeltraject"
      keywords={[
        `Logopediepraktijk`,
        `Mireille Peters`,
        `Zeewolde`,
        `Harderwijk`,
        `Ermelo`,
      ]}
    />
    <div className="treatment">
      <h2 className="treatment__header">Behandeltraject</h2>
      <div className="container-fluid">
        <div className="row">
          {data.behandeltrajecten.edges.map(behandeltraject => (
            <div key={behandeltraject.node.id} className="col-md-12 col-lg-4 ">
              <div className="card">
                <div className="card-header">{behandeltraject.node.title}</div>
                <div className="card-body">
                  <p className="card-text treatment__text">
                    {behandeltraject.node.omschrijving.omschrijving}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    behandeltrajecten: allContentfulBehandeltraject {
      edges {
        node {
          id
          title
          omschrijving {
            omschrijving
          }
        }
      }
    }
  }
`
export default IndexPage
